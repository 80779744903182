.startPaused {
  label {
    padding-left: 12px;
  }

  label::before {
    margin-left: -12px;
  }

  label::after {
    margin-left: -12px;
  }
}

.systemDropdown {
  ul {
    max-height: 250px !important;
  }
}

.consumerDropdown {
  ul {
    max-height: 170px !important;
  }
}

.spinnerCenter {
  width: 100%;
  height: 396px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  font-weight: 400;

  strong {
    font-weight: 800;
  }

  &Error {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #f1556c;
  }
}

.dividerSlim {
  height: 0.5px;
  background-color: #455260;
  width: 100%;
}

.inputDate::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}
