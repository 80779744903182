$dark: #343a40;
$white: #fff;
$gray-500: #adb5bd !default;

.records {
  width: 100%;
  font-size: 14px;

  .title {
    font-size: inherit;

    span {
      display: inline;
      color: $white;
    }
  }

  .alignRight {
    text-align: right;
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;

    button {
      min-width: 120px;

      span {
        margin-left: 5px;
      }
    }

    > :nth-child(n) {
      margin: 0;
    }

    > :not(:first-child) {
      margin-left: 10px;
    }
  }
}

.row {
  margin-bottom: 24px;
}
