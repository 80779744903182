.notFoundContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  figure {
    margin: 0;

    img {
      width: 500px;
      margin-bottom: 50px;
    }
  }

  h1 {
    margin: 20px 10px;
  }
}
