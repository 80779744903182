.selectField {
  display: flex;
  flex-direction: column;

  .dropdown {
    ul {
      max-height: 300px;
      overflow-y: scroll;
    }

    button {
      display: flex;
      width: 100% !important;
      justify-content: space-between;
    }

    button:first-child {
      width: 100%;
      text-align: left;
    }

    button:last-child {
      width: 45px;
    }
  }
}
