.modalBody {
  background: #323b44;
}

.dontBreak {
  overflow-wrap: break-word;
}

.dividerSlim {
  height: 0.5px;
  background-color: #455260;
  width: 100%;
}
