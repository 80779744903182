.buttonAddProperty {
  margin-bottom: 1rem;
}

.badge {
  border-radius: 0.25rem;
  width: 100%;
  background-color: rgb(69, 82, 96);
  //background-color: #414d59;
  padding: 0.5rem;
  word-break: break-all;
  margin: 0.5rem 0;
}

.badgeDelete {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f672a7;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border: none;

  &:hover {
    background-color: #f6609c;
  }
}
