.root {
  position: fixed;
  z-index: 1201;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #323b44;
  -webkit-tap-highlight-color: transparent;
}

.transparent {
  background-color: transparent;
}
