.logout-checkmark {
  width: 120px;
  padding: 20px 0;
  margin: 0 auto;
}

.logout-path {
  animation: dash 2s ease-in-out;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.logout-spin {
  animation: spin 2s;
  transform-origin: 50% 50%;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes text {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes text {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
