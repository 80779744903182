.tableCellWrapper {
  text-align: end;
  white-space: nowrap;

  > :not(:first-child) {
    margin-left: 12px;
  }
}

.cellWrapper {
  word-wrap: break-word;
  white-space: normal;
  max-width: 450px;
}

.rowTitle {
  margin-right: 8px;
}

.alignBottom {
  vertical-align: bottom;
}
